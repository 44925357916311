import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import HeroContent from '../components/heroContent';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import { convertToBgImage } from 'gbimage-bridge';
import { RichText } from 'prismic-reactjs';

const BasicImagesContainer = ({ imageFields }) => {
  return (
    <div className='flex w-full flex-row flex-wrap items-center justify-center lg:mt-10 mega:mt-12 mega:px-40'>
      {imageFields.reverse().map((field, index) => {
        const imageFluid = field.image.gatsbyImageData;
        const alt = field.image.alt;
        const url = field.image.url;
        return (
          <div
            key={index}
            className='m-1 h-full w-full lg:my-4 lg:mx-0 lg:w-1/2'
          >
            <a href={url} rel='noopened noreferrer' target='_blank'>
              <GatsbyImage
                image={imageFluid}
                alt={alt}
                className='mx-2 object-cover shadow-lg'
                style={{ maxHeight: `400px` }}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

const PhotosPage = ({ data }) => {
  const {
    hero_title,
    banner_image,
    banner_graphic,
    banner_description_content,
    image_collection,
  } = data.prismicPhotosPage.data;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);
  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  return (
    <Layout
      pageTitle={RichText.asText(hero_title.richText)}
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      heroSlantClassName='hero-slant lg:hero-slant-lg'
      contentInsideBackground={() => (
        <FullWidthContainer className='pt-4 md:mt-8 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(hero_title.richText)}
            heroDescription={banner_description_content.richText}
          />
        </FullWidthContainer>
      )}
    >
      <div className='py-24'>
        <BasicImagesContainer imageFields={image_collection} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PhotosPageQuery {
    prismicPhotosPage {
      data {
        image_collection {
          image {
            alt
            gatsbyImageData
            url
          }
        }
        hero_title {
          richText
        }
        banner_image {
          alt
          url
          gatsbyImageData
        }
        banner_graphic {
          alt
          url
          gatsbyImageData
        }
        banner_description_content {
          richText
        }
      }
    }
  }
`;

export default PhotosPage;
